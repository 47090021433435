<template>
    <div class="scene scene--3" ref="scene">
        <div class="home__wrap__scene home__wrap__scene3" v-if="data">
            <div class="our-legacy" v-scroll-reveal="{ distance: '0px', beforeReveal: revealTitle }">
                <h2 class="our-legacy__header title-line1">OUR<br>VISION</h2>
                <h3 class="our-legacy__subheading title-line2">YOUR<br>VOICE</h3>
                <img src="../../assets/global/sms-logo--mark.svg" alt="Santa Monica Studios logo mark"
                     class="our-legacy__logo--mark title-logo-mark">
            </div>
            <div class="scene3__mond" v-scroll-reveal="{ distance: '0px', beforeReveal: scrollReveal, desktop: false }">
                <!-- Our Legacy Mondrian -->
                <svg role = "img" aria-label = "A decorative frame border." enable-background="new 0 0 876.6 692.9" viewBox="0 0 876.6 692.9"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="scene3MondSVG">
                    <clipPath id="mondo2a">
                        <path
                            d="m2 690.9v-116.9c0-31.6 12.8-60.2 33.5-80.9s49.3-33.5 80.9-33.5h116.9v117c0 31.6-12.8 60.2-33.5 80.9s-49.3 33.5-80.9 33.5h-116.9z"/>
                    </clipPath>
                    <clipPath id="mondo2b">
                        <path
                            d="m233.4 459.5v-231.2c0-62.5 25.3-119 66.3-160s97.5-66.3 160-66.3h231.2v55.1 176.2c0 62.5-25.3 119-66.3 160s-97.5 66.3-160 66.3h-231.2z"/>
                    </clipPath>
                    <g clip-path="url(#mondo2a)">
                        <image height="803" overflow="visible" transform="matrix(.2866 0 0 .2866 2 460.2666)"
                               width="803" :xlink:href="data.ourLegacy.image2.src" class="s3-image2"><desc>{{ data.ourLegacy.image2.alt }}</desc></image>
                    </g>
                    <g clip-path="url(#mondo2b)">
                        <image height="803" overflow="visible" transform="matrix(.5682 0 0 .5682 234.6434 2)"
                               width="803" :xlink:href="data.ourLegacy.image1.src" class="s3-image1"><desc>{{ data.ourLegacy.image1.alt }}</desc></image>
                    </g>
                    <!-- white fill -->
                    <path d="m233.4 344.1h114.4v114.4h-114.4z" fill="#fff" class="s3-white-box"/>
                    <!-- yellow fill -->
                    <path d="m118.9 458.9h114.4v114.4h-114.4z" fill="#dfd9c8" class="s3-yellow-box"/>

                    <!-- red -->
                    <g style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                        <path d="m233.4 459.5h641.2" class="s3-bottom-line"/>
                        <path d="m232.6 575.2h-114.9" class="s3-yellow-line1"/>
                        <path d="m117.7 575.2v-114.9" class="s3-yellow-line2"/>
                        <path d="m117.7 458.3c0-31.6 12.8-60.2 33.5-80.9s49.3-33.5 80.9-33.5" class="s3-small-curve"/>
                        <path d="m232.1 343.8h117" class="s3-white-line2"/>
                        <path d="m349.1 343.8v115.5" class="s3-white-line1"/>
                        <path d="m233.4 459.5v117c0 31.6-12.8 60.2-33.5 80.9s-49.3 33.5-80.9 33.5h-117"
                              class="s3-bottom-curve1"/>
                        <path d="m2 690.9v-116.9c0-31.6 12.8-60.2 33.5-80.9s49.3-33.5 80.9-33.5h116.9"
                              class="s3-bottom-curve2"/>
                        <path d="m690.9 2v55.1 176.2c0 62.5-25.3 119-66.3 160s-97.5 66.3-160 66.3h-231.2"
                              class="s3-top-curve1"/>
                        <path d="m233.4 459.5v-231.2c0-62.5 25.3-119 66.3-160s97.5-66.3 160-66.3h231.2"
                              class="s3-top-curve2"/>
                    </g>

                    <!-- tan -->
                    <g style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                        <path d="m233.4 459.5h641.2" class="s3-bottom-line"/>
                        <path d="m232.6 575.2h-114.9" class="s3-yellow-line1"/>
                        <path d="m117.7 575.2v-114.9" class="s3-yellow-line2"/>
                        <path d="m117.7 458.3c0-31.6 12.8-60.2 33.5-80.9s49.3-33.5 80.9-33.5" class="s3-small-curve"/>
                        <path d="m232.1 343.8h117" class="s3-white-line2"/>
                        <path d="m349.1 343.8v115.5" class="s3-white-line1"/>
                        <path d="m233.4 459.5v117c0 31.6-12.8 60.2-33.5 80.9s-49.3 33.5-80.9 33.5h-117"
                              class="s3-bottom-curve1"/>
                        <path d="m2 690.9v-116.9c0-31.6 12.8-60.2 33.5-80.9s49.3-33.5 80.9-33.5h116.9"
                              class="s3-bottom-curve2"/>
                        <path d="m690.9 2v55.1 176.2c0 62.5-25.3 119-66.3 160s-97.5 66.3-160 66.3h-231.2"
                              class="s3-top-curve1"/>
                        <path d="m233.4 459.5v-231.2c0-62.5 25.3-119 66.3-160s97.5-66.3 160-66.3h231.2"
                              class="s3-top-curve2"/>
                    </g>

                    <!-- black -->
                    <g style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                        <path d="m233.4 459.5h641.2" class="s3-bottom-line"/>
                        <path d="m232.6 575.2h-114.9" class="s3-yellow-line1"/>
                        <path d="m117.7 575.2v-114.9" class="s3-yellow-line2"/>
                        <path d="m117.7 458.3c0-31.6 12.8-60.2 33.5-80.9s49.3-33.5 80.9-33.5" class="s3-small-curve"/>
                        <path d="m232.1 343.8h117" class="s3-white-line2"/>
                        <path d="m349.1 343.8v115.5" class="s3-white-line1"/>
                        <path d="m233.4 459.5v117c0 31.6-12.8 60.2-33.5 80.9s-49.3 33.5-80.9 33.5h-117"
                              class="s3-bottom-curve1"/>
                        <path d="m2 690.9v-116.9c0-31.6 12.8-60.2 33.5-80.9s49.3-33.5 80.9-33.5h116.9"
                              class="s3-bottom-curve2"/>
                        <path d="m690.9 2v55.1 176.2c0 62.5-25.3 119-66.3 160s-97.5 66.3-160 66.3h-231.2"
                              class="s3-top-curve1"/>
                        <path d="m233.4 459.5v-231.2c0-62.5 25.3-119 66.3-160s97.5-66.3 160-66.3h231.2"
                              class="s3-top-curve2"/>
                    </g>
                </svg>
                <p class="home__wrap__scene3__copy" v-html="data.ourLegacy.copy"></p>

                <div class="cta-btn mobile-only" v-scroll-reveal="{ desktop: false }">
                    <router-link to="/who-we-are">
                        <div class="btn btn--red-outline">
                            <span>WHO WE ARE</span>
                        </div>
                    </router-link>
                </div>

            </div>
            <div class="scene3__right desktop-only">
                <div class="scene3__right__quote">
                    <div class="story__quote">
                        <div class="story__quote__copy story__quote__copy--small"
                            v-html="data.ourLegacy.quote.textSmallBR" v-if="data.ourLegacy.quote.textSmallBR !== null"></div>
                        <div class="story__quote__copy story__quote__copy--large"
                            v-html="data.ourLegacy.quote.textLarge" v-if="data.ourLegacy.quote.textLarge !== null"></div>
                        <div class="attribution" :class="{ 'no-bullet' : data.ourLegacy.quote.sourceTitle == null}" v-if="data.ourLegacy.quote.sourceName !== null">
                            {{ data.ourLegacy.quote.sourceName }}</div>
                        <div class="role" v-if="data.ourLegacy.quote.sourceTitle !== null">{{ data.ourLegacy.quote.sourceTitle }}</div>
                    </div>
                </div>
                <div class="scene3__right__button">
                    <router-link to="/who-we-are">
                        <diamond-button>WHO WE ARE</diamond-button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import DiamondButton from '../buttons/DiamondButton';
import HomeTitleReveal from '../../mixins/HomeTitleReveal.js'

import gsap, {Linear, Power3} from 'gsap'
import DrawSVGPlugin from "gsap/DrawSVGPlugin"

gsap.registerPlugin(DrawSVGPlugin)

export default {
    mixins: [HomeTitleReveal],
    props: ['data', 'isMobile'],
    components: {
        DiamondButton
    },
    data () {
        return {
            tl: null,
        }
    },
    methods: {
        scrollReveal () {

            if (!this.isMobile) return;

            var speed = 0.85;
            var vw = $(window).width();

            this.tl = gsap.timeline({ delay: 0.2 })
            this.tl.timeScale(1.8);

            var stagger2 = 0.15;

            var scene3Mond = gsap.timeline({})
            scene3Mond.timeScale(1.7);
            scene3Mond.fromTo($('.s3-top-curve1'), 2, { drawSVG: '100% 100%' }, {
                drawSVG: '100%',
                ease: Power3.easeInOut,
                stagger: stagger2
            }, 0);
            scene3Mond.fromTo($('.s3-top-curve2'), 2, { drawSVG: '0% 0%' }, {
                drawSVG: '100%',
                ease: Power3.easeInOut,
                stagger: stagger2
            }, 0);
            scene3Mond.fromTo($('.s3-bottom-line'), 2, { drawSVG: '0% 0%' }, {
                drawSVG: '100%',
                ease: Power3.easeInOut,
                stagger: stagger2
            }, 0);
            scene3Mond.fromTo($('.s3-bottom-curve1'), 2, { drawSVG: '0% 0%' }, {
                drawSVG: '100%',
                ease: Power3.easeInOut,
                stagger: stagger2
            }, 0);
            scene3Mond.fromTo($('.s3-bottom-curve2'), 2, { drawSVG: '100% 100%' }, {
                drawSVG: '100%',
                ease: Power3.easeInOut,
                stagger: stagger2
            }, 0);
            scene3Mond.from($('.s3-image1'), 2, {
                opacity: 0,
                scale: 0.76,
                transformOrigin: '50% 50%',
                ease: Power3.easeInOut,
                stagger: stagger2
            }, 1.3);
            scene3Mond.from($('.s3-image2'), 2, {
                opacity: 0,
                scale: 0.37,
                transformOrigin: '50% 50%',
                ease: Power3.easeInOut,
                stagger: stagger2
            }, 1.3);
            scene3Mond.from($('.s3-yellow-box'), 0.65, {
                opacity: 0,
                ease: Power3.easeInOut,
                stagger: stagger2
            }, 1.2);
            scene3Mond.fromTo($('.s3-white-line1'), 0.4, { drawSVG: '100% 100%' }, {
                drawSVG: '100%',
                ease: Power3.easeIn,
                stagger: stagger2
            }, 0.7);
            scene3Mond.fromTo($('.s3-white-line2'), 0.2, { drawSVG: '100% 100%' }, {
                drawSVG: '100%',
                ease: Linear.easeNone,
                stagger: stagger2
            }, 1.1);
            scene3Mond.fromTo($('.s3-small-curve'), 0.25, { drawSVG: '100% 100%' }, {
                drawSVG: '100%',
                ease: Power3.easeOut,
                stagger: stagger2
            }, 1.3);
            scene3Mond.fromTo($('.s3-yellow-line1'), 0.2, { drawSVG: '0% 0%' }, {
                drawSVG: '100%',
                ease: Linear.easeNone,
                stagger: stagger2
            }, 1.1);
            scene3Mond.fromTo($('.s3-yellow-line2'), 0.25, { drawSVG: '0% 0%' }, {
                drawSVG: '100%',
                ease: Power3.easeOut,
                stagger: stagger2
            }, 1.3);

        }
    }
}
</script>

