<template>
    <div class="slider-perks" v-if="data && data.length >= 5">

        <div class="slider-perks__left-description">
            <h1>The Perks and Benefits</h1>
            <router-link to="/stories/our-perks-and-benefits">
                <diamond-button>SEE ALL</diamond-button>
            </router-link>
        </div>

        <div class="slider-perks__swiper--vertical">
            <div class="arrow-up" tabindex="0" @keyup.enter="onUpArrowClick" @click="onUpArrowClick">
                <img src="../assets/ourStudio/arrow-up.svg" alt = "Up arrow icon for the perks slider">
            </div>
            <div class="sp-icons">
                <div class="icon" v-for="(icon, i) in slots" :style="{'transform': 'translateY(' + (slots[i].y) + 'px)' }" :data-index="i">
                    <img :src="data.slice()[i].icon" class="icon__icon" :alt = "data.slice()[i].title + ' icon'">
                    <img src="../assets/ourStudio/perks-diamond.svg" class="icon__diamond" alt = "">
                </div>
            </div>
            <div class="arrow-down" tabindex="0" @keyup.enter="onUpArrowClick" @click="onDownArrowClick">
                <img src="../assets/ourStudio/arrow-down.svg" alt = "Down arrow icon for the perks slider">
            </div>
        </div>

        <div class="slider-perks__right-side">
            <div class="slider-perks__right-side__bg" :style="{ 'background-image': 'url(' + curLargeIcon + ')'}"></div>
            <div class="dots">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot desktop"></div>
                <div class="dot desktop"></div>
            </div>
            <div class="description">
                <p class="description__pagination">{{ curPerkNum }}/{{ data.length }}</p>
                <h1 class="description__title">{{ title }}</h1>
                <p class="description__copy" v-html="description"></p>
            </div>
        </div>

    </div>
</template>



<script>
    import Vue from 'vue'
    import DiamondButton from '../components/buttons/DiamondButton';
    import gsap, {Power3} from 'gsap'

    export default {
        props: ['passedInData'],
        components: {
            DiamondButton,
        },
        data() {
            return {
                title: '',
                description: '',
                curPerkNum: 1,
                curLargeIcon: '',
                slots: [{
                        y: 100,
                        scale: 0,
                        opacity: 0,
                        class: 'inactive upper'
                    },
                    {
                        y: 170,
                        scale: 0.75,
                        opacity: 1,
                        class: 'inactive upper'
                    },
                    {
                        y: 300,
                        scale: 1,
                        opacity: 1,
                        class: 'active center'
                    },
                    {
                        y: 430,
                        scale: 0.75,
                        opacity: 1,
                        class: 'inactive lower'
                    },
                    {
                        y: 500,
                        scale: 0,
                        opacity: 0,
                        class: 'inactive lower'
                    }
                ],
                dataNonReactive: [],
                data: null
            }
        },
        mounted() {

            this.data = this.passedInData.perks;

            if(this.data.length < 5) {
                console.log('There must be at least 5 perks');
                return;
            }

            // add numbers into data object
            for (var i=0; i < this.data.length; i++) {
                this.data[i].number = i+1                
            }

            // shift array two spots so first item is 2nd index
            // e.g. [6, 7, 0, 1, 2, 3, 4, 5]
            this.data.unshift(this.data.pop());
            this.data.unshift(this.data.pop());

            // clone array so we have non-reactive copy
            this.dataNonReactive = this.data.slice();

            Vue.nextTick(() => {
                this.animateIcons();
            });

        },
        methods: {
            onUpArrowClick() {
                var lastItemInArray = this.dataNonReactive.pop();
                this.dataNonReactive.unshift(lastItemInArray);
                this.setSlotIndexes(1);
                this.animateIcons(1);
            },
            onDownArrowClick() {
                var firstItemInArray = this.dataNonReactive.shift();
                this.dataNonReactive.push(firstItemInArray);
                this.setSlotIndexes(-1);
                this.animateIcons(-1);
            },
            setSlotIndexes(direction) {
                var $icons = $('.icon');
                for (var i = 0; i < $icons.length; i++) {
                    var $curIcon = $($icons[i]);
                    var curIndex = parseInt($curIcon.attr('data-index'));
                    var newIndex = curIndex + direction;
                    if (newIndex < 0 && direction == -1) newIndex = 4;
                    if (newIndex > 4 && direction == 1) newIndex = 0;
                    $curIcon.attr('data-index', newIndex);
                }
            },
            animateIcons(direction) {
                var $icons = $('.icon');
                for (var i = 0; i < $icons.length; i++) {
                    var $curIcon = $($icons[i]);
                    var curIndex = parseInt($curIcon.attr('data-index'));
                    var speed = 0.7;

                    // down arrow click
                    // move top-most item directly to bottom and set new content
                    if (direction == -1 && curIndex == 4) {
                        speed = 0;
                        // $curIcon.find('span').html(this.dataNonReactive[4].num);
                        $curIcon.find('img.icon__icon').attr('src', this.dataNonReactive[4].icon);
                    }

                    // up arrow click
                    // move bottom-most item directly to top and set new content
                    if (direction == 1 && curIndex == 0) {
                        speed = 0;
                        // $curIcon.find('span').html(this.dataNonReactive[0].num);
                        $curIcon.find('img.icon__icon').attr('src', this.dataNonReactive[0].icon);
                    }

                    // apply 'active' or 'inactive' class
                    $curIcon.removeClass('active inactive upper lower center').addClass(this.slots[curIndex].class);

                    // set current title and description
                    if (this.slots[curIndex].class == 'active center') {
                        this.title = this.dataNonReactive[curIndex].title;
                        this.description = this.dataNonReactive[curIndex].description;
                        this.curPerkNum = this.dataNonReactive[curIndex].number;
                        this.curLargeIcon = this.dataNonReactive[curIndex].icon;
                        gsap.fromTo('.slider-perks__right-side__bg', 0.75, { scale:1.3, opacity:0}, { scale:1, opacity:0.05, ease:Power3.easeInOut});
                        gsap.fromTo('.description', 0.75, { scale:1.3, opacity:0}, { scale:1, opacity:1, ease:Power3.easeInOut});

                    }

                    gsap.to($curIcon, speed, {
                        y: this.slots[curIndex].y,
                        opacity: this.slots[curIndex].opacity,
                        scale: this.slots[curIndex].scale,
                        force3D: true,
                        ease: Power3.easeInOut
                    });

                }
            }
        }
    }
</script>