<template>
    <div class="scene scene--6">
        <div class="home__wrap__scene home__wrap__scene6">
            <h2 v-scroll-reveal="{ distance: '100px', desktop: false, origin: 'right' }">The Perks<br>And<br>Benefits</h2>
            <perks-slider :passedInData="data" v-scroll-reveal="{ desktop: false }"/>
        </div>
    </div>
</template>

<script>
    import PerksSlider from '@/components/PerksSlider.vue'

    export default {
        props: ['data', 'isMobile'],
        components: {
            PerksSlider
        },
        data() {
            return {
                tl: null
            }
        },
        methods: {
            scrollReveal() {

                if (!this.isMobile) return;

                var speed = 0.85;
                var vw = $(window).width();

                this.tl = gsap.timeline({delay:0.2})
                this.tl.timeScale(1.8);

                
            }
        }
    }
</script>