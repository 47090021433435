<template>
    <div class="scene scene--1">
        <div class="home__wrap__scene home__wrap__scene1">

            <div class="home__wrap__scene1__a" v-scroll-reveal="{ distance: '0px', beforeReveal: scrollReveal, desktop: false }">

                <svg role = "img" aria-label = "A fancy border for an image." class="mond" enable-background="new 0 0 720.3 499.9" viewBox="0 0 720.3 499.9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <clipPath id="a">
                        <path d="m718.3 2c-46 0-90 8.1-130.9 22.8-148 53.6-253.8 195.4-253.8 361.9h384.7z" />
                    </clipPath>
                    <g clip-path="url(#a)">
                        <image class="m-image" height="800" overflow="visible" transform="matrix(.4802 0 0 .4802 333.8709 .876)" width="800" :xlink:href="data.godOfWar.image.src"><desc>{{ data.godOfWar.image.alt }}</desc></image>
                    </g>
                    <!-- RED -->
                    <path class="m-white-fill" d="m499.3 386.4v-110.9c110.9 0 109.5 110.9 109.5 110.9z" fill="#fff" />
                    <path class="m-yellow-fill" d="m718.2 387v110.9c-110.9 0-109.5-110.9-109.5-110.9z" fill="#dfd9c8" />

                    <path class="m-white-leg" d="m499.3 386.4v-110.9" style="fill:#fff;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                    <path class="m-bottom-line" d="m92 386.7h626.3" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                    <circle class="m-dot1" cx="2" cy="386.9" fill="#b22028" r="2" />
                    <circle class="m-dot2" cx="32" cy="386.9" fill="#b22028" r="2" />
                    <circle class="m-dot3" cx="63" cy="386.9" fill="#b22028" r="2" />
                    <g style="stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;fill:none">
                        <path class="m-white-curve" d="m608.7 386.4s1.4-110.9-109.5-110.9" />
                        <path class="m-yellow-curve" d="m608.7 387s-1.4 110.9 109.5 110.9" />
                        <path class="m-top-curve" d="m718.3 2c-46 0-90 8.1-130.9 22.8-148 53.6-253.8 195.4-253.8 361.9" />
                        <path class="m-right-line" d="m718.2 497.9v-496" />
                    </g>

                    <!-- TAN -->
                    <path class="m-white-leg" d="m499.3 386.4v-110.9" style="fill:#fff;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                    <path class="m-bottom-line" d="m92 386.7h626.3" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                    <circle class="m-dot1" cx="2" cy="386.9" fill="#d6d0b4" r="2" />
                    <circle class="m-dot2" cx="32" cy="386.9" fill="#d6d0b4" r="2" />
                    <circle class="m-dot3" cx="63" cy="386.9" fill="#d6d0b4" r="2" />
                    <g style="stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;fill:none">
                        <path class="m-white-curve" d="m608.7 386.4s1.4-110.9-109.5-110.9" />
                        <path class="m-yellow-curve" d="m608.7 387s-1.4 110.9 109.5 110.9" />
                        <path class="m-top-curve" d="m718.3 2c-46 0-90 8.1-130.9 22.8-148 53.6-253.8 195.4-253.8 361.9" />
                        <path class="m-right-line" d="m718.2 497.9v-496" />
                    </g>

                    <!-- BLACK -->
                    <path class="m-white-leg" d="m499.3 386.4v-110.9" style="fill:#fff;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                    <path class="m-bottom-line" d="m92 386.7h626.3" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                    <circle class="m-dot1" cx="2" cy="386.9" fill="#232221" r="2" />
                    <circle class="m-dot2" cx="32" cy="386.9" fill="#232221" r="2" />
                    <circle class="m-dot3" cx="63" cy="386.9" fill="#232221" r="2" />
                    <g style="stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;fill:none">
                        <path class="m-white-curve" d="m608.7 386.4s1.4-110.9-109.5-110.9" />
                        <path class="m-yellow-curve" d="m608.7 387s-1.4 110.9 109.5 110.9" />
                        <path class="m-top-curve" d="m718.3 2c-46 0-90 8.1-130.9 22.8-148 53.6-253.8 195.4-253.8 361.9" />
                        <path class="m-right-line" d="m718.2 497.9v-496" />
                    </g>

                </svg>

                <div class="watch-trailer">
                    <div class="watch-trailer__inner">
                        <router-link :to="'/video/' + data.godOfWar.trailer.assets[0].id" tabindex="0">
                            <div class="watch-trailer__inner__bg" :style="{ 'background-image' :  'url('+data.godOfWar.trailer.image.src+')' }" role = "image" :alt="data.godOfWar.trailer.image.alt"></div>
                            <div class="watch-trailer__inner__play">
                                <img src="../../assets/home/scene1/icon-play.svg" alt = "Video play icon image">
                                <h4>{{ data.godOfWar.trailer.cta }}</h4>
                            </div>
                        </router-link>
                    </div>
                </div>

                <img id="our-journey-mobile" src="/img/home/our-journey-mobile.svg" alt = "Image that has an arrow on the left pointing to text on the right that reads 'Our Journey. Your Story.'" />

            </div>

            <div class="home__wrap__scene1__b">
                <div class="before-title">OUR JOURNEY</div>
                <h2>YOUR <span><br></span>STORY</h2>
                <p>Mastering our collective craft, by empowering <span><br></span>your individual growth.</p>
                <div class="cta-btn">
                    <router-link to="/careers">
                        <div class="btn btn--red-outline">
                            <span>APPLY TODAY</span>
                        </div>
                    </router-link>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import gsap, {Power2, Power3} from 'gsap'
    import DrawSVGPlugin from "gsap/DrawSVGPlugin"
    gsap.registerPlugin(DrawSVGPlugin)

    export default {
        props: ['data', 'isMobile'],
        data() {
            return {
                tl: null
            }
        },
        methods: {
            scrollReveal() {

                if (!this.isMobile) return;

                var speed = 0.85;
                var vw = $(window).width();

                this.tl = gsap.timeline({delay:0.2})
                this.tl.timeScale(1.8);

                this.tl.fromTo($('.home__wrap__scene1__a').find('.mond'), 0.85 * 2,
                    { x: -50, force3D: true },
                    { x: 0, ease: Power2.easeInOut },
                    0);

                // scene 1 Mondrian animation
                let stagger = 0.3;
                this.tl.fromTo($('.m-right-line'), 2, { drawSVG: '22% 22%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger}, 0);
                this.tl.fromTo($('.m-right-line'), 0.4, { opacity: 0 }, { opacity: 1 , stagger: stagger}, 0);
                this.tl.fromTo($('.m-bottom-line'), 2, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger}, 0);
                this.tl.fromTo($('.m-bottom-line'), 0.4, { opacity: 0 }, { opacity: 1 , stagger: stagger}, 0);
                this.tl.fromTo($('.m-white-curve'), 1.2, { drawSVG: '0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger}, 1);
                this.tl.fromTo($('.m-yellow-curve'), 1.2, { drawSVG: '0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger}, 1);
                this.tl.fromTo($('.m-white-leg'), 1.2, { drawSVG: '0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger}, 1);
                this.tl.fromTo($('.m-dot1'), 0.3, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 1.55);
                this.tl.fromTo($('.m-dot2'), 0.3, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 1.45);
                this.tl.fromTo($('.m-dot3'), 0.3, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 1.35);
                this.tl.fromTo($('.m-top-curve'), 1.1, { drawSVG: '0%' }, { drawSVG: '100%', ease: Power2.easeInOut , stagger: stagger}, 1.6);
                this.tl.fromTo($('.m-yellow-fill'), 1.3, { opacity: 0 }, { opacity: 1 }, "-=1.7");
                this.tl.from($('.m-image'), 1.3, { opacity: 0, scale: 0.64, transformOrigin: '50% 50%', ease: Power2.easeInOut }, "-=1.7");

                this.tl.fromTo('.watch-trailer', speed,
                    { x: vw / 6, opacity: 0, force3D: true },
                    { x: 0, opacity: 1, ease: Power2.easeInOut }, '-=0.2');

                this.tl.fromTo('#our-journey-mobile', speed,
                    { x: vw / 6, opacity: 0, force3D: true },
                    { x: 0, opacity: 1, ease: Power2.easeInOut }, '-=0.74');

                // Apply button
                this.tl.fromTo($('.home__wrap__scene1__b').find('.cta-btn'), speed,
                    { x: vw / 6, opacity: 0, force3D: true },
                    { x: 0, opacity: 1, ease: Power2.easeInOut },
                    '-=' + ((speed * 0.8) + 0));

                // Cutting edge
                this.tl.fromTo($('.home__wrap__scene1__b').find('h3'), speed,
                    { x: vw / 6, opacity: 0, force3D: true },
                    { x: 0, opacity: 1, ease: Power2.easeInOut },
                    '-=' + (speed * 0.97));

                // God of War
                this.tl.fromTo($('.home__wrap__scene1__b').find('h2'), speed,
                    { x: vw / 6, opacity: 0, force3D: true },
                    { x: 0, opacity: 1, ease: Power2.easeInOut },
                    '-=' + (speed * 0.97));

                // The creators of
                this.tl.fromTo($('.home__wrap__scene1__b').find('h5'), speed,
                    { x: vw / 6, opacity: 0, force3D: true },
                    { x: 0, opacity: 1, ease: Power2.easeInOut },
                    '-=' + (speed * 0.97));




            }
        },
    }
</script>
